import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Vimeo } from "@swizec/gatsby-theme-course-platform";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "show-a-preloader"
    }}>{`Show a Preloader`}</h1>
    <Vimeo id={429646517} mdxType="Vimeo" />
    <p><img parentName="p" {...{
        "src": "https://raw.githubusercontent.com/Swizec/react-d3js-es6-ebook/2018-version/manuscript/resources/images/es6v2/preloader-screenshot.png",
        "alt": "Preloader screenshot"
      }}></img></p>
    <p>{`Our preloader is a screenshot of the final result. Usually you'd have to wait
until the end of the project to make that, but I'll just give you mine.
Starting with the preloader makes sense for two reasons:`}</p>
    <ol>
      <li parentName="ol">{`It's nicer than looking at a blank screen while data loads`}</li>
      <li parentName="ol">{`It's a good sanity check for our environment`}</li>
    </ol>
    <p>{`We're using a screenshot of the final result because the full dataset takes a
few seconds to load, parse, and render. It looks better if visitors see
something informative while they wait.`}</p>
    <p>{`React Suspense is about to make building preloaders a whole lot better.
Adapting to the user's network speed, built-in preload functionality, stuff
like that. More on that in the chapter on React Suspense and Time Slicing.`}</p>
    <p>{`Make sure you've installed `}<a parentName="p" {...{
        "href": "/local-environment/4"
      }}>{`all dependencies`}</a>{` and that `}<inlineCode parentName="p">{`npm start`}</inlineCode>{` is running.`}</p>
    <p>{`We're building our preloader in 4 steps:`}</p>
    <ol>
      <li parentName="ol">{`Get the image`}</li>
      <li parentName="ol">{`Make the `}<inlineCode parentName="li">{`Preloader`}</inlineCode>{` component`}</li>
      <li parentName="ol">{`Update `}<inlineCode parentName="li">{`App`}</inlineCode></li>
      <li parentName="ol">{`Load Bootstrap styles in `}<inlineCode parentName="li">{`index.js`}</inlineCode></li>
    </ol>
    <h2 {...{
      "id": "step-1-get-the-image"
    }}>{`Step 1: Get the image`}</h2>
    <Vimeo id={429646611} mdxType="Vimeo" />
    <p>{`Download `}<a parentName="p" {...{
        "href": "https://github.com/Swizec/react-d3-walkthrough-livecode/raw/master/src/assets/preloading.png"
      }}>{`my screenshot from Github`}</a>{`
and save it in `}<inlineCode parentName="p">{`src/images/preloader-screenshot.png`}</inlineCode>{`. It goes in the `}<inlineCode parentName="p">{`src/images/`}</inlineCode>{`
directory because we're going to `}<inlineCode parentName="p">{`import`}</inlineCode>{` it in JavaScript (which makes it part
of our source code), and I like to put non-JavaScript files in `}<inlineCode parentName="p">{`assets`}</inlineCode>{`. Keeps
the project organized.`}</p>
    <h2 {...{
      "id": "step-2-preloader-component"
    }}>{`Step 2: Preloader component`}</h2>
    <Vimeo id={429646877} mdxType="Vimeo" />
    <p>{`Our `}<inlineCode parentName="p">{`Preloader`}</inlineCode>{` is a small component that pretends it's the `}<inlineCode parentName="p">{`App`}</inlineCode>{` and renders a
static title, description, and a screenshot of the end result. It goes in
`}<inlineCode parentName="p">{`src/components/Preloader.js`}</inlineCode>{`.`}</p>
    <p>{`We'll put all of our components in `}<inlineCode parentName="p">{`src/components/`}</inlineCode>{`.`}</p>
    <p>{`We start the component off with some imports, an export, and a functional
stateless component that returns an empty div element.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/Preloader.js

import React from "react"

import PreloaderImg from "../images/preloader-screenshot.png"

const Preloader = () => <div className="App container"></div>

export default Preloader
`}</code></pre>
    <p>{`We `}<inlineCode parentName="p">{`import`}</inlineCode>{` React (which we need to make JSX syntax work) and the
`}<inlineCode parentName="p">{`PreloaderImg`}</inlineCode>{` for our image. We can import images because of the Webpack
configuration that comes with `}<inlineCode parentName="p">{`create-react-app`}</inlineCode>{`. The webpack image loader
returns a URL that we put in the `}<inlineCode parentName="p">{`PreloaderImg`}</inlineCode>{` constant.`}</p>
    <p>{`At the bottom, we `}<inlineCode parentName="p">{`export default Preloader`}</inlineCode>{` so that we can use it in `}<inlineCode parentName="p">{`App.js`}</inlineCode>{`
as `}<inlineCode parentName="p">{`import Preloader`}</inlineCode>{`. Default exports are great when your file exports a
single object. Named exports when you want to export multiple items. You'll see
that play out in the rest of this project.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`Preloader`}</inlineCode>{` function takes no props (because we don't need any) and returns
an empty `}<inlineCode parentName="p">{`div`}</inlineCode>{`. Let's fill it in.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/Preloader.js

const Preloader = () => (
  <div className="App container">
    <h1>The average H1B in tech pays $86,164/year</h1>
    <p className="lead">
      Since 2012 the US tech industry has sponsored 176,075 H1B work visas. Most
      of them paid <b>$60,660 to $111,668</b> per year (1 standard deviation).{" "}
      <span>
        The best city for an H1B is <b>Kirkland, WA</b> with an average
        individual salary <b>$39,465 above local household median</b>. Median
        household salary is a good proxy for cost of living in an area.
      </span>
    </p>
    <img src={PreloaderImg} style={{ width: "100%" }} alt="Loading preview" />
    <h2 className="text-center">Loading data ...</h2>
  </div>
)
`}</code></pre>
    <p>{`A little cheating with grabbing copy from the future, but that's okay. In real
life you'd use some temporary text, then fill it in later.`}</p>
    <p>{`The code itself looks like HTML. We have the usual tags - `}<inlineCode parentName="p">{`h1`}</inlineCode>{`, `}<inlineCode parentName="p">{`p`}</inlineCode>{`, `}<inlineCode parentName="p">{`b`}</inlineCode>{`,
`}<inlineCode parentName="p">{`img`}</inlineCode>{`, and `}<inlineCode parentName="p">{`h2`}</inlineCode>{`. That's what I like about JSX: it's familiar. Even if you don't
know React, you can guess what's going on here.`}</p>
    <p>{`But look at the `}<inlineCode parentName="p">{`img`}</inlineCode>{` tag: the `}<inlineCode parentName="p">{`src`}</inlineCode>{` attribute is dynamic, defined by
`}<inlineCode parentName="p">{`PreloaderImg`}</inlineCode>{`, and the `}<inlineCode parentName="p">{`style`}</inlineCode>{` attribute takes an object, not a string. That's
because JSX is more than HTML; it's JavaScript. Think of props as function
arguments – any valid JavaScript fits.`}</p>
    <p>{`That will be a cornerstone of our project.`}</p>
    <h2 {...{
      "id": "step-3-update-app"
    }}>{`Step 3: Update App`}</h2>
    <Vimeo id={429647218} mdxType="Vimeo" />
    <p>{`We use our new Preloader component in App – `}<inlineCode parentName="p">{`src/App.js`}</inlineCode>{`. Let's remove the
`}<inlineCode parentName="p">{`create-react-app`}</inlineCode>{` defaults and import our `}<inlineCode parentName="p">{`Preloader`}</inlineCode>{` component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/App.js

import React from "react"
// Delete the line(s) between here...
import logo from "./logo.svg"
import "./App.css"
// ...and here.

// Insert the line(s) between here...
import Preloader from "./components/Preloader"
// ...and here.

class App extends React.Component {
  // Delete the line(s) between here...
  render() {
    return (
      <div className="App">
        <div className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <h2>Welcome to React</h2>
        </div>
        <p className="App-intro">
          To get started, edit <code>src/App.js</code> and save to reload.
        </p>
      </div>
    )
  }
  // ...and here.
}

export default App
`}</code></pre>
    <p>{`We removed the logo and style imports, added an import for `}<inlineCode parentName="p">{`Preloader`}</inlineCode>{`, and
gutted the `}<inlineCode parentName="p">{`App`}</inlineCode>{` class. It's great for a default app, but we don't need that
anymore.`}</p>
    <p>{`Let's define a default `}<inlineCode parentName="p">{`techSalaries`}</inlineCode>{` state and render our `}<inlineCode parentName="p">{`Preloader`}</inlineCode>{`
component when there's no data.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/App.js

function App() {
  const [techSalaries, setTechSalaries] = useState([])

  if (techSalaries.length < 1) {
    return <Preloader />
  } else {
    return <div className="App"></div>
  }
}
`}</code></pre>
    <p>{`Nowadays we can define properties directly in the class body without a
constructor method. It's not part of the official JavaScript standard yet, but
most React codebases use this pattern.`}</p>
    <p>{`Properties defined this way are bound to each instance of our components so
they have the correct `}<inlineCode parentName="p">{`this`}</inlineCode>{` value. Late you'll see we can use this shorthand
to neatly define event handlers.`}</p>
    <p>{`We set `}<inlineCode parentName="p">{`techSalaries`}</inlineCode>{` to an empty array by default. In `}<inlineCode parentName="p">{`render`}</inlineCode>{` we use object
destructuring to take `}<inlineCode parentName="p">{`techSalaries`}</inlineCode>{` out of component state, `}<inlineCode parentName="p">{`this.state`}</inlineCode>{`, and
check whether it's empty. When `}<inlineCode parentName="p">{`techSalaries`}</inlineCode>{` is empty our component renders
the preloader, otherwise an empty div.`}</p>
    <p>{`If your `}<inlineCode parentName="p">{`npm start`}</inlineCode>{` is running, the preloader should show up on your screen.`}</p>
    <p><img parentName="p" {...{
        "src": "https://raw.githubusercontent.com/Swizec/react-d3js-es6-ebook/2018-version/manuscript/resources/images/es6v2/preloader-without-styles-screenshot.png",
        "alt": "Preloader without Bootstrap styles"
      }}></img></p>
    <p>{`Hmm… that's not very pretty. Let's fix it.`}</p>
    <h2 {...{
      "id": "step-4-load-bootstrap-styles"
    }}>{`Step 4: Load Bootstrap styles`}</h2>
    <Vimeo id={429647370} mdxType="Vimeo" />
    <p>{`We're going to use Bootstrap styles to avoid reinventing the wheel. We're
ignoring their JavaScript widgets and the amazing integration built by the
`}<a parentName="p" {...{
        "href": "https://react-bootstrap.github.io/"
      }}>{`react-bootstrap`}</a>{` team. Just the
stylesheets please.`}</p>
    <p>{`They'll make our fonts look better, help with layouting, and make buttons look
like buttons. We `}<em parentName="p">{`could`}</em>{` use styled components, but writing our own styles
detracts from this tutorial.`}</p>
    <p>{`We load stylesheets in `}<inlineCode parentName="p">{`src/index.js`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/index.js

import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
// Insert the line(s) between here...
import "bootstrap/dist/css/bootstrap.css"
// ...and here.

ReactDOM.render(<App />, document.getElementById("root"))
`}</code></pre>
    <p>{`Another benefit of Webpack: `}<inlineCode parentName="p">{`import`}</inlineCode>{`-ing stylesheets. These imports turn into
`}<inlineCode parentName="p">{`<style>`}</inlineCode>{` tags with CSS in their body at runtime.`}</p>
    <p>{`This is also a good opportunity to see how `}<inlineCode parentName="p">{`index.js`}</inlineCode>{` works to render our app
👇`}</p>
    <ol>
      <li parentName="ol">{`loads `}<inlineCode parentName="li">{`App`}</inlineCode>{` and React`}</li>
      <li parentName="ol">{`loads styles`}</li>
      <li parentName="ol">{`Uses `}<inlineCode parentName="li">{`ReactDOM`}</inlineCode>{` to render `}<inlineCode parentName="li">{`<App />`}</inlineCode>{` into the DOM`}</li>
    </ol>
    <p>{`That's it.`}</p>
    <p>{`Your preloader screen should look better now.`}</p>
    <p><img parentName="p" {...{
        "src": "https://raw.githubusercontent.com/Swizec/react-d3js-es6-ebook/2018-version/manuscript/resources/images/es6v2/preloader-screenshot.png",
        "alt": "Preloader screenshot"
      }}></img></p>
    <p>{`If you don't, try comparing your changes to this
`}<a parentName="p" {...{
        "href": "https://github.com/Swizec/reactdataviz-project/commit/1c1ba48d7ccfb9ce6b5590a5ae7bd735e92a5633"
      }}>{`diff on Github`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      